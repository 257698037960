import './Footer.css'

const Footer = () => (
  <footer className='footer'>
     <p>&copy; <b>Jay Two</b>. All Rights Reserved.</p> <br/> 
     Pages template is made by <b>Raj Shekhar</b>.
  </footer>
)

export default Footer


import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
// import WebIcon from '@material-ui/icons/Web'
import YouTubeIcon from '@material-ui/icons/YouTube'
// import ToysIcon from '@material-ui/icons/Toys'

import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, avatar, description, resume, social } = about

  return (
    <div className='about center'>
      
      {name && (
        <center><h1><span className='about__name'>{name}</span></h1></center>
      )}

      {role && <center><h2 className='about__role'>{role}</h2></center>}

      <br/>

      {avatar && (
        <center><img src={avatar} alt="" width="30%" /></center>
      )}

      <center><p className='about__desc'>{description && description}</p></center>

      <div className='about__contact center'>
        
        {resume && (
          <a href={resume}>
            <span type='button' className='btn btn--outline'>
              Resume
            </span>
          </a>
        )}

        {social && (
          <>

            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
                target='_blank'
                rel='noreferrer'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
                target='_blank'
                rel='noreferrer'                
              >
                <LinkedInIcon />
              </a>
            )}

            {social.youtube && (
              <a
                href={social.youtube}
                aria-label='youtube'
                className='link link--icon'
                target='_blank'  
                rel='noreferrer'                              
                   >
                <YouTubeIcon />
              </a>
            )}

          </>
        )}
      </div>
    </div>
  )
}

export default About


const header = { 
  homepage: 'https://j2doll.github.io/', 
  title: 'Jay Two',
}

const about = {
  name: 'Jay Two' ,
  role: 'Software' ,
  // avatar: 'https://avatars.githubusercontent.com/u/1289306?v=4' , 
  // description: '반갑습니다!' ,
  // social: { 
    // github: 'https://github.com/j2doll', 
    // youtube: 'https://youtube.com/j2doll',
  // }, 
}

const projects = [ 
  
  {
    name: 'Open Source', 
    // thumbnail: 'https://raw.githubusercontent.com/QtExcel/QXlsx/master/markdown.data/QXlsx-Desktop.png',
    description: 'Open source projects currently in progress on GitHub.',
    stack: ['Qt', 'C++', 'C', 'cmake', 'QML', 'Android', ], 
    sourceCode: 'https://j2doll.github.io/simple-portfolio/',
    // livePreview: 'https://github.com/QtExcel',
  }, 
  

]

const skills = [
  // 'C++',
]

const contact = {
  email: 'jaytwo@jaytwo.kr',
  // phone: '',
}

export { header, about, projects, skills, contact }


